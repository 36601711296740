@font-face {
font-family: '__Poppins_5c6549';
src: url(/_next/static/media/4b4ebe20759bdbf2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__Poppins_5c6549';
src: url(/_next/static/media/98512bf8da1afe43-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__Poppins_5c6549';
src: url(/_next/static/media/8001a01b32b71ef5-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__Poppins_5c6549';
src: url(/_next/static/media/1a15c5ce3e29c0a9-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__Poppins_5c6549';
src: url(/_next/static/media/8b20023e8f811f53-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}@font-face {font-family: '__Poppins_Fallback_5c6549';src: local("Arial");ascent-override: 92.83%;descent-override: 30.94%;line-gap-override: 8.84%;size-adjust: 113.11%
}.__className_5c6549 {font-family: '__Poppins_5c6549', '__Poppins_Fallback_5c6549'
}

@font-face {
font-family: '__Walsheim_23513b';
src: url(/_next/static/media/ef2ed3fe5889c409-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__Walsheim_23513b';
src: url(/_next/static/media/cb6cf8da7daadc31-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__Walsheim_Fallback_23513b';src: local("Arial");ascent-override: 88.73%;descent-override: 24.16%;line-gap-override: 0.00%;size-adjust: 101.43%
}.__className_23513b {font-family: '__Walsheim_23513b', '__Walsheim_Fallback_23513b'
}

@font-face {
font-family: '__HafferSQ_c5865c';
src: url(/_next/static/media/e77b4512934feff6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__HafferSQ_c5865c';
src: url(/_next/static/media/de3a8c7431c92e4d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__HafferSQ_Fallback_c5865c';src: local("Arial");ascent-override: 89.24%;descent-override: 18.76%;line-gap-override: 0.00%;size-adjust: 101.30%
}.__className_c5865c {font-family: '__HafferSQ_c5865c', '__HafferSQ_Fallback_c5865c'
}

@font-face {
font-family: '__TWKEverett_dbdc38';
src: url(/_next/static/media/ca2e52fadfce028b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__TWKEverett_dbdc38';
src: url(/_next/static/media/5cbc028f2c457197-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__TWKEverett_dbdc38';
src: url(/_next/static/media/c6d0a7a5f32a6929-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__TWKEverett_Fallback_dbdc38';src: local("Arial");ascent-override: 91.90%;descent-override: 19.20%;line-gap-override: 9.10%;size-adjust: 109.91%
}.__className_dbdc38 {font-family: '__TWKEverett_dbdc38', '__TWKEverett_Fallback_dbdc38'
}

